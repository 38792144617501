/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import pkgJson from "../../../package.json";

const Footer = () => {
  return (
    <footer id="footer" className="component footer component-footer">
      {/* <div className="container contato">
            <div className="row">
                <div className="6u 12u$(medium)">
                    <h6>Endereço</h6>

                    <p>
                        <a target="_blank" rel="noopener noreferrer" href="http://maps.google.com/?q=Academia Pedro Martinez">Estrada de
                        Itapecirica 3285, sobreloja <br />
                        Jd. Germânia, CEP: 05835-005 <br />
                        São Paulo - SP</a>
                    </p>
                </div>

                <div className="6u 12u$(medium)">
                    <h6>Contato</h6>

                    <p>
                        <a target="_blank" rel="noopener noreferrer" href="tel:(11) 98870-1860">Tel: (11) 98870-1860</a><br />
                        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511988701860?text=Contato%20pelo%20site%20">WhatsApp: (11) 98870-1860</a>
                    </p>
                </div>
            </div>
        </div> */}

      <div className="container">
        {/* <h6 id="contato">Fale conosco pelas nossas redes sociais</h6>
            <br />
            <ul className="icons">
                <li><a target="_blank" rel="noopener noreferrer" href="https://web.facebook.com/academiapedromatinez/"
                    className="icon fa-facebook"></a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuWhFMEY8wR1sR5wD6UNhlQ"
                    className="icon fa-youtube"></a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/pedromartinezteam/"
                    className="icon fa-instagram"></a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511988701860?text=Contato%20pelo%20site%20"
                    className="icon fa-whatsapp"></a></li>
            </ul> */}
        <ul className="copyright">
          <li>&copy; Academia Pedro Martinez</li>
          <li>
            <a
              href="https://www.nuratech.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              By Nuratech
            </a>
          </li>
          <li>v. {pkgJson.version}</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
